import { element } from 'prop-types';
import { lazy } from 'react';
const APIDocumentation = lazy(() =>
  import('../views/pages/Documentation/API/APIDocumentationView')
);
const Reports = lazy(() => import('../views/pages/Dashboard/Reports/Reports'));
const PageNotFound = lazy(() => import('../views/pages/PageNotFound'));
const WorkInProgress = lazy(() => import('../views/pages/WorkInProgress'));
const Dashboard = lazy(() => import('../views/pages/Dashboard/Dashboard/HomeDashboard'));
const TwilioVIPOTest = lazy(() => import('../views/pages/TwilioTest/TwilioVIPOTest'));
const TwilioVtnaTest = lazy(() => import('../views/pages/TwilioTest/TwilioVTNATest'));
const TwilioPaymentTest = lazy(() => import('../views/pages/TwilioTest/TwilioPayment'));

const CallFlow = lazy(() => import('../views/pages/Dashboard/CallFlow/CallFlow'));
// const FastLaneView = lazy(() => import('../views/pages/Dashboard/PricingRules/FastLaneView'));
const FastLaneView = lazy(() => import('../views/pages/Dashboard/PricingRules/FastLaneView'));
const PayPhoneView = lazy(() => import('../views/pages/Dashboard/PricingRules/CallPassView'));
const VtnaView = lazy(() => import('../views/pages/Dashboard/PricingRules/VtnaView'));
const VippView = lazy(() => import('../views/pages/Dashboard/PricingRules/VippView'));
const API = lazy(() => import('../views/pages/Dashboard/InterConnection/API'));
const VoIP = lazy(() => import('../views/pages/Dashboard/InterConnection/VoIP'));
const IVR = lazy(() => import('../views/pages/Dashboard/InterConnection/IVR'));
const Accounts = lazy(() => import('../views/pages/Dashboard/Settings/Accounts'));
const Users = lazy(() => import('../views/pages/Dashboard/Settings/Users'));
const Roles = lazy(() => import('../views/pages/Dashboard/Settings/Roles'));
const Products = lazy(() => import('../views/pages/Dashboard/Products/ProductsView'));

const privateRoutes = [
  {
    path: '/quickpay',
    exact: true,
    name: 'QuickPay',
    element: TwilioPaymentTest
  },
  {
    path: '/vipo',
    exact: true,
    name: 'VIPO',
    element: TwilioVIPOTest
  },
  {
    path: '/ventana',
    exact: true,
    name: 'Ventana',
    element: TwilioVtnaTest
  },
  {
    path: '/documentation',
    exact: true,
    name: 'Dashboard',
    element: APIDocumentation
  },
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    element: Dashboard
  },
  {
    path: '/call-flow',
    exact: true,
    name: 'Access Numbers',
    element: CallFlow
  },
  {
    path: '/fastlane',
    exact: true,
    name: 'FastLane',
    element: FastLaneView
  },
  {
    path: '/payphone',
    exact: true,
    name: 'CallPass',
    element: PayPhoneView
  },
  {
    path: '/vipp',
    exact: true,
    name: 'Vipp',
    element: VippView
  },
  {
    path: '/vnta',
    exact: true,
    name: 'Vnta',
    element: VtnaView
  },
  { path: '/calls', exact: true, name: 'Reports', element: Reports },
  { path: '/payments', exact: true, name: 'Reports', element: Reports },
  { path: '/revenue', exact: true, name: 'Reports', element: Reports },
  { path: '/api', exact: true, name: 'API', element: API },
  { path: '/voip', exact: true, name: 'VoIP', element: VoIP },
  {
    path: '/ivr-config',
    exact: true,
    name: 'IVR Configuration',
    element: IVR
  },
  {
    path: '/products',
    exact: true,
    name: 'Products',
    element: Products
  },
  {
    path: '/accounts',
    exact: true,
    name: 'Accounts',
    element: Accounts
  },
  { path: '/users', exact: true, name: 'Users', element: Users },
  {
    path: '/security',
    exact: true,
    name: 'Roles',
    element: Roles
  },
  {
    path: '/support',
    exact: true,
    name: 'Support',
    element: WorkInProgress
  },
  { path: '*', name: 'Page Not Found', element: PageNotFound }
];

export default privateRoutes;
